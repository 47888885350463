<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="database" />
				<span>终端</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 终端类型 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">终端类型</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteData">
							删除
						</a-button>
						
					</div>
				</div>

				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :total="total_page * 10"
				 :data-source="equiplist" rowKey="type_id" size="small">
					<span slot="doing" slot-scope="text, data">
						<span style="color: #1890ff;cursor: pointer;" @click="edit(data)">更新版本</span>
					</span>
				</a-table>
			</div>
		</div>
		
		<a-modal :title="form.type_id ? '更新' : '添加'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="uruleForm" :model="form" :rules="urules"  :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="最新版本号" prop="version">
							<a-input v-model="form.version" placeholder="请输入最新版本号" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="更新内容：" prop="content">
							<a-textarea v-model="form.content"  placeholder="请输入最新版本更新内容"  :auto-size="{ minRows: 2, maxRows: 5 }"/>
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="上传文件" >
							<a-spin tip="上传文件中..." :spinning="spinning">
								<a-upload accept=".apk" name="file" :customRequest="customRequest"	>
									<a-button>
										<a-icon type="upload" /> 上传文件
									</a-button>
								</a-upload>
								<p style="line-height: 20px;">{{form.url}}</p>
							</a-spin>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>

		<a-modal v-model="addEditShow" :title="isAdd ? '新增终端类型' : '编辑终端类型'" :footer="null">
			<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="类型名称：" prop="org_name">
							<a-input v-model="info.org_name" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="厂商名称：" prop="factory">
							<a-input v-model="info.factory" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="软件简称：" prop="type_name">
							<a-input v-model="info.type_name" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="AppKey：" prop="jg_appid">
							<a-input v-model="info.jg_appid" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="Master Secret：" prop="jg_secret">
							<a-input v-model="info.jg_secret" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="备注：" prop="remark">
							<a-textarea v-model="info.remark" :auto-size="{ minRows: 2, maxRows: 5 }"/>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="add()">
						保存
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="left" v-if="!isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="updateEquip">
						修改
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="right" v-if="!isAdd" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	const columns = [
		{
			title: "类型名称",
			dataIndex: "org_name",
		},
		{
			title: "厂商名称",
			dataIndex: "factory",
		},
		{
			title: "软件简称",
			dataIndex: "type_name",
		},
		{
			title: "最新版本号",
			dataIndex: "version",
		},
		{
			title: "AppKey",
			dataIndex: "jg_appid",
		},
		{
			title: "Master Secret",
			dataIndex: "jg_secret",
		},
		{
			title: "备注",
			dataIndex: "remark",
		},
		{
			title: "版本更新时间",
			dataIndex: "update_time",
		},
		{
			title: "操作",
			width: 100,
			dataIndex: "doing",
			scopedSlots: {
				customRender: 'doing'
			},
		},
	];
	
	import http from '../http'
	export default {
		data() {
			return {				
				formKey: Math.random(),
				spinning: false,
				modelVisible: false,
				expand: false,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				show1: false,
				show4: false,
				// 编辑与添加
				addEditShow: false,
				// 是否添加状态
				isAdd: false,
				// 搜索
				searchData: {
					page: 1
				},
				// 终端类型列表
				equiplist: [],
				// 多少条数据 * 10
				total_page: 0,

				// 添加与编辑的数据
				info: {
					type_id: 0,
					org_name:'',
					factory:'',
					type_name: '',
					jg_appid: '',
					jg_secret:'',
					remark:'',
				},
				rules: {
					org_name: [{
						required: true,
						message: '请输入终端类型名称',
						trigger: 'blur'
					}, ],
					factory: [{
						required: true,
						message: '请输入厂商名称',
						trigger: 'blur'
					}, ],
					type_name: [{
						required: true,
						message: '请输入软件简称',
						trigger: 'blur'
					}, ],
				},
				urules: {
					version: [{
						required: true,
						message: '请输入最新版本号',
						trigger: 'blur'
					}, ],
					url: [{
						required: true,
						message: '请上传最新客户端版本',
						trigger: 'blur'
					}, ],
					content: [{
						required: true,
						message: '请输入最新版本更新内容',
						trigger: 'blur'
					}, ],
				},
				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0
			}; 
		},
		computed: {
			count() {
				return this.expand ? 11 : 7;
			},
		},
		mounted() {
			this.getList()
		},
		methods: {
			customRequest(data) {
				this.spinning = true
				let formData = new FormData()
				// 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
				formData.append('file', data.file)
				http({
					method: 'post',
					url: '/api.config.upload.file/',
					ContentType: {
						'Content-Type': 'multipart/form-data'
					},
					data: {
						isFile: true,
						file: data.file
					}
				}).then((res) => {
					if (res.code == 200) {
						this.form.url = res.data
					} else {
						this.form.url = ''
						this.$message.warning('文件上传失败');
					}
					this.spinning = false
				})
			},
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},

			handleReset() {
				this.form.resetFields();
			},

			toggle() {
				this.expand = !this.expand;
			},

			handleChange() {},

			handleSelectChange(value) {
				console.log(value);
				this.form.setFieldsValue({
					note: `Hi, ${value === "male" ? "man" : "lady"}!`,
				});
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.info = this.selectedRowDataList[this.editIndex]
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						type_id: 0,
						org_name: '',
						type_name: '',
						jg_appid: '',
						jg_secret: '',
						remark: '',
						factory:'',
					}
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择终端类型');
						return
					}

					let selectDatas = []
					this.equiplist.forEach((v) => {
						if (this.selectedRowKeys.includes(v.type_id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					this.info = this.selectedRowDataList[0]
					this.isAdd = false
				}

				this.addEditShow = true
			},
			edit(data) {
				this.form = {
					...data
				}
				this.modelVisible = true;
			},

			handleOk() {
				if (!this.form.url) {
					this.$message.warning('请先上传文件');
					return
				}
				this.$refs.uruleForm.validate(valid => {
					if (valid) {
						let newData = {
							type_id:this.form.type_id,
							url:this.form.url,
							version:this.form.version,
							content:this.form.content,
						}
						http({
							method: 'post',
							url: '/api.type.update.version/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.type_id) {
									this.$message.success('最新版本信息更新成功');
								} else {
									this.$message.success('最新版本信息发布成功');
								}
								this.getList()
								this.modelVisible = false;
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.getList()
			},
			// 搜索
			search() {
				this.searchData.page = 1
				this.getList()
			},
			// 终端类型列表
			getList() {
				http({
					method: 'post',
					url: '/api.type.list/',
					data: this.searchData
				}).then((res) => {
					this.equiplist = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 
			deleteData() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择终端类型');
					return
				}

				this.$confirm({
					title: '是否删除选中终端类型?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk : () => {
						let selectDatas = []
						this.equiplist.forEach((v) => {
							if(this.selectedRowKeys.includes(v.type_id)) {
								selectDatas.push(v.type_id)
							}
						})
						http({
							method: 'post',
							url: '/api.type.del/',
							data: {
								type_id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除终端类型成功');
								this.getList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});


			},
			// 添加终端类型
			add (type) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.type.add/',
							data: {...this.info}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改终端类型信息成功' : '添加终端类型成功');
								this.getList()
							} else {
								this.$message.error(res.data);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 更新终端类型
			updateEquip() {
				this.add('edit')
			}
		},
	};
</script>
<style lang="less">
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.tes {
		width: 90%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.table-th {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e8e8e8;
			height: 30px;

			div {
				width: 33.33%;
				box-sizing: border-box;
				height: 30px;
				padding-left: 20px;
				font-weight: 700;
				border-right: 1px solid #e8e8e8;
				display: flex;
				align-items: center;

				&:last-child {
					border-right: none;
				}
			}
		}

		.taes {
			display: flex;
			align-items: center;

			&>div {
				width: 33.33%;
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 50px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 20px;
				}
			}

			.left {
				height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				height: 300px;
				border-right: 1px solid #e8e8e8;
			}

			.right {
				height: 300px;
			}
		}
	}
</style>
